.blog-main-container {
  .blog-description {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: normal;
  }

  .featured-img-wrapper {
      img {
          width: 100%;
          height: 125px;
          object-fit: cover;
      }
  }

  h6, h5 {
      color: #549AFF;
  }
}

