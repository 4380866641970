.fab-pagination-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 8px;
  background-color: white;
  border-radius: 4px;


  .fab-pagination-results {
    font-size: 14px;
    color: #549aff;
    font-weight: 500;
  }

  .fab-pagination-controls {
    display: flex;
    gap: 6px;
    align-items: center;
    flex-wrap: wrap;

    button {
      width: 36px;
      height: 36px;
      padding: 0;
      background-color: white;
      color: #333;
      border: 1px solid #ddd;
      border-radius: 50%;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        stroke: #333;
      }

      &:hover:not(:disabled):not(.active) {
        background-color: #e6f0ff;
        border-color: #549aff;
        color: #549aff;

        svg {
          stroke: #549aff;
        }
      }

      &.active {
        background-color: #549aff;
        border-color: #549aff;
        color: white;
        font-weight: 700;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);

        svg {
          stroke: white;
        }
      }

      &:disabled {
        background-color: #f0f0f0;
        color: #aaa;
        cursor: not-allowed;

        svg {
          stroke: #aaa;
        }
      }
    }

    .fab-pagination-ellipsis {
      padding: 0 8px;
      color: #888;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .fab-page-size-selector {
    padding: 8px 12px;
    border: 1px solid #549aff;
    border-radius: 8px;
    background-color: white;
    color: #333;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      border-color: darken(#549aff, 10%);
    }
  }
}
