.fab-leader-board-container {
  font-family: 'Barlow';
  font-style: normal;
  min-height: 600px;
  position: relative;

  .fab-leader-board-header {
    background: white;
    border-radius: 4px;
  }

  .fab-btn-group {
    .fab-btn-grp-btn-text {
      font-size: 12px;
      padding: 4px 12px;
    }
  }

  .fab-lb-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 4px;
    color: #549aff;
    width: 28px;
    height: 28px;
    background: #ebf3ff;
    border-radius: 60%;
  }

  .fab-lb-btn-text {
    font-size: 14px;
    margin-inline: 12px;
  }

  .w3-center {
    text-align: center;
  }
}

.custom-margin-top {
  margin: 0px 16px 16px 0px;
}

@media (max-width: 600px) {
  .custom-margin-top {
    margin: 16px !important;
  }
}
