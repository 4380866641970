.fab-report-list-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 0px;
  overflow-y: auto;

  @media (max-width: 600px) {
    padding: 16px;
  }

  .fab-scam-top-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 12px;
  }

  .fab-page-heading {
    padding-left: 4px;
    flex: 1 1 100%;

    @media (min-width: 600px) {
      flex: 1;
    }
  }

  .fab-top-actions {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 1 100%;

    @media (min-width: 600px) {
      flex: none;
    }
  }

  .fab-search-container {
    position: relative;
    flex: 1;
  }

  .fab-search-box {
    width: 100%;
    height: 32px;
  }

  .fab-search-icon {
    position: absolute;
    top: 1.2px;
    right: 1.2px;
    height: 28px;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

    img {
      margin: 5px;
      margin-top: 4px;
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }

  .fab-filter-toggle-btn {
    background-color: white;
    border: none;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #eef5ff;
    }

    &.active {
      background-color: #549aff;
      ;
    }
  }

  .fab-filter-section {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 4px;
  }

  .fab-filter-item {
    flex: 1 1 calc(50% - 12px);

    @media (min-width: 768px) {
      flex: 1;
    }
  }

  .fab-rl-filter-label {
    display: inline-block;
    margin: 4px;
    color: rgba(128, 128, 128, 0.7);
    font-size: 12px;
  }

  .fab-date-picker {
    width: 100%;
    padding: 5px 10px;
    font-size: 14px;
    border: 1px solid #cce0ff;
    background: #f5f9ff;
    border-radius: 4px;
  }
}

.fab-report-list-headers {
  background: white;
  border-radius: 4px;
  padding: 8px;
}