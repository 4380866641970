.fab-dropdown-container {
  position: relative;
  display: flex;
  background: #f5f9ff;
  border: 1px solid #cce0ff;
  box-sizing: border-box;
  border-radius: 4px;
  height: 36px;

  &.fab-dropdown-focused {
    background: #ffffff;
    border: 1px solid #549aff;
  }

  .fab-dropdown-selection {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 10px;
    cursor: pointer;
    &.fab-dropdown-disabled {
      cursor: not-allowed;
    }

    .fab-dropdown-icon {
      margin-left: auto;
      margin-right: -4px;
    }

    .fab-dropdown-placeholder {
      font-family: 'Barlow';
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #bec4d4;
      margin-right: 15px;
    }

    .fab-dropdown-selected-value {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #121212;
      margin-right: 15px;
    }
  }

  .fab-dropdown-options-container {
    position: absolute;
    z-index: 999;
    max-height: 200px;
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
    background: #ffffff;
    top: 100%;
    margin-top: 5px;
    border: 1px solid #549aff;
    border-radius: 4px;
    box-sizing: border-box;

    .fab-dropdown-option {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 12px;
      color: #121212;
      padding: 10px;
      cursor: pointer;
      &.selected {
        font-weight: 500;
        color: #ffffff !important;
        background-color: #549aff !important;
      }
      &:hover {
        background-color: #f0f6fe;
      }
    }

    .fab-dropdown-no-options,
    .fab-dropdown-add-new {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #121212;
      padding: 10px;
      cursor: pointer;
    }

    .fab-dropdown-no-options {
      background-color: #ffffff;
      border-bottom: 1px solid #cce0ff;
    }

    .fab-dropdown-add-new {
      background-color: #f0f6fe;
      color: #549aff;
      margin-top: 8px;
      cursor: pointer;
      &:hover {
        background-color: #e0ebfe;
      }
    }
  }

  .fab-dropdown-search-input {
    width: 100%;
    background-color: transparent;
  }
}
