//Report scam page
@mixin sy-card-status {
  background-color: #f5f0cb;
  color: #888600;

  &.active {
    color: #4d9c4c;
    background: #e2f5e2;
  }

  &.rejected {
    background-color: #f6dad9;
    color: #e5281c;
  }
}

.fab-scam-reg-card-container {
  position: relative;
  display: flex;
  z-index: 1;
  padding-right: 16px;

  @media (max-width: 600px) {
    padding: 16px;
  }

  .fab-scam-reg-card {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    z-index: 3;
    background: #ffffff;
    border-radius: 8px;
    padding: 15px;

    .fab-scam-reg-card-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
    }

    .fab-scam-reg-card-warning {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 4px;
      padding: 10px;
      margin-top: 12px;
      background-color: #ffeaef;
      margin-bottom: 12px;

      img {
        margin-right: 12px;
      }

      .fab-scam-reg-card-warning-text {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #fb4222;
        white-space: pre-wrap;
      }
    }

    .fab-file-card-container {
      display: block;
      cursor: pointer;
      margin: 0;
      padding: 8px;
      margin-top: 10px;
      border-radius: 4px;
      border: 1px solid #cce0ff;
      background-color: #f6fafe;

      input {
        display: none !important;
      }
    }

    .fab-file-card-choose-file {
      border: 1px dashed #549aff;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .fab-file-card-choose-file-text-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 8px;
      }

      .fab-file-card-sub-text-main {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #549aff;
        padding-top: 5px;
      }

      .fab-file-card-sub-text {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #6e7a8a;
        padding-bottom: 8px;
      }
    }

    .fab-file-card-selected-file {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 8px;

      .fab-file-card-selected-file-name {
        padding: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .fab-textarea-container {
      textarea {
        width: 100%;
        height: 150px;
        resize: none;
      }
    }
  }
}

.fab-report-list-card {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 6px;
  // padding: 10px;
  margin: 10px 0px;
  overflow: hidden;

  .fab-report-list-card-inner {
    font-family: 'Barlow';
    font-style: normal;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .fab-report-list-card-inner {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .fab-report-list-card-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #121212;
      margin-bottom: 8px;
      width: 280px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .fab-report-list-card-sub-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #6e7a8a;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .fab-report-list-card-sub-desc {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #121212;
      margin-left: 6px;
    }

    .fab-report-list-card-status {
      padding: 4px 10px;
      // margin: 0px 10px;
      border-radius: 4px;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      @include sy-card-status;
    }

    .fab-report-list-card-btn-section {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: end;
    }

    .fab-report-list-card-justify-space-btw {
      justify-content: space-between;
    }

    .fab-report-list-card-verify-btn {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #549aff;
      height: 28px;
      background: #ebf3ff;
      border-radius: 4px;
      min-width: 100px;
    }
  }
}

//Report detail page

.fab-report-detail-container {
  position: relative;
  min-height: 50vh;

  .fab-report-detail-top-section {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-right: 16px;
  }

  .fab-report-detail-card {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 20px;
    overflow: hidden;

    .fab-report-detail-card-inner {
      font-family: 'Barlow';
      font-style: normal;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .fab-report-detail-card-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #121212;
        margin-bottom: 8px;
        overflow-wrap: anywhere;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .fab-report-detail-card-status {
        padding: 4px 10px;
        border-radius: 4px;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        @include sy-card-status;
      }
    }

    .fab-report-list-card-verify-btn {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #549aff;
      height: 28px;
      background: #ebf3ff;
      border-radius: 4px;
      min-width: 100px;
    }

    .fab-report-detail-proof-title {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #121212;
    }

    .fab-report-detail-proof-type {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #6e7a8a;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .fab-report-detail-proof-img-container {
      display: flex;
      flex-direction: column;

      .fab-report-detail-proof-img {
        width: 100%;
        height: 170px;
        object-fit: contain;
        border-radius: 6px;
      }
    }

    .fab-report-detail-proof-download-section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.img-download {
        padding-top: 8px;
      }

      .fab-report-detail-file-name {
        width: '85%';
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #121212;
      }
    }

    .fab-report-detail-description-container {
      .fab-report-detail-dc-title {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #121212;
        margin-bottom: 8px;
      }

      .fab-report-detail-dc-text {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        //color: #667180;
      }

      .fab-report-detail-dc-reported-by {
        margin-bottom: 4px;
      }
    }
  }

  .fab-report-detail-list-card {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 6px;
    padding: 10px;
    // margin-bottom: 20px;
    overflow: hidden;

    .fab-report-detail-card-inner {
      font-family: 'Barlow';
      font-style: normal;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .fab-report-detail-card-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #121212;
        margin-bottom: 8px;
        overflow-wrap: anywhere;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .fab-report-detail-card-status {
        padding: 4px 10px;
        border-radius: 4px;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        @include sy-card-status;
      }
    }
  }

  .fab-scam-verify-container {
    padding: 16px;
  }

  .fab-report-list-verify-modal {
    max-width: 540px;
    padding-top: 175px;
  }

  .fab-report-list-verify-modal-content {
    max-width: 400px;
    border-radius: 8px;
    padding: 15px;
  }
}

.fab-report-detail-dc-sub-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6e7a8a;

  &.custom-min-width-106 {
    margin-right: 4px;
    min-width: 106px;
  }

  &.custom-min-width-90 {
    margin-right: 4px;
    min-width: 90px;
    margin-top: 8px;
  }
}

.fab-report-detail-card-sub-desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #121212;

  &.display-flex {
    display: flex;
  }
}