.fab-password-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.fab-password-input {
    width: 100%;
    padding-right: 40px;
    /* Space for the icon */
}

.fab-password-toggle {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fab-password-toggle:hover {
    color: #000;
}