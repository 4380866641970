.fab-table-container {
  font-family: 'Barlow';
  font-style: normal;
  min-height: 480px;

  // Table Styling
  .w3-table-all {
    width: 100%;
    border: none;
    border-collapse: collapse;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    thead {
      border: 4px solid white;
    }

    th,
    td,
    tr {
      padding: 8px 12px;
      text-align: left;
      border: none;
    }

    th {
      background-color: #eef5ff;
      color: #549aff;
      font-weight: 600;
      border: none;
    }

    td {
      font-size: 14px;
      font-weight: 500;

      &.lb-name {
        font-weight: 400;
      }
    }

    tr {
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f0f5ff; // Light blue on hover
      }

      &:nth-child(even) {
        background-color: #f0f6fe88; // Subtle alternating row background
      }
    }
  }

  // Table Row Styling
  .fab-lb-tr {
  }

  // Button Styling
  .fab-lb-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 4px;
    color: #549aff;
    width: 28px;
    height: 28px;
    background: #ebf3ff;
    border-radius: 60%;
  }
}
