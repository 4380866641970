.blog-list-container {
  // overflow-y: auto; /* Enable vertical scrolling */
  
  /* Flexbox to arrange items in two columns per row */
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto the next line */
  gap: 16px; /* Space between items */
}

.blog-list-item {
  flex: 0 0 48%; /* Adjust width as needed */
}

/* Responsive Layout for Mobile */
@media (max-width: 1024px) {
  .blog-list-item {
    flex: 0 0 100%; /* On smaller screens, each item will take up 100% of the container width */
  }
}

.blog-list-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.blog-list-container::-webkit-scrollbar-thumb {
  background-color: #fff; /* Blue theme for the thumb */
  border-radius: 4px; /* Rounded corners */
  border: 2px solid #fff; /* White border for a minimal look */
}

.blog-list-container::-webkit-scrollbar-thumb:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.blog-list-container::-webkit-scrollbar-track {
  background-color: #fff; /* Light gray track */
  border-radius: 4px; /* Rounded corners for the track */
}

/* Firefox scrollbar styling */
.blog-list-container {
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scrollbar-color: #fff #fff; /* Thumb and track colors for Firefox */
}

.blog-list-container-wrapper {
  // height: 120vh; /* Full viewport height */
  display: flex;
  flex-direction: column; /* Align content vertically */
  // overflow: hidden; /* Prevent the parent container from scrolling */
  padding-bottom: 16px;

  @media (max-width: 600px) {
    padding: 16px;
  }
}

/* Optional: Style for the "No data available" message */
.fab-no-data {
  color: #555;
  font-size: 1.2rem;
  margin-top: 20px;
}
